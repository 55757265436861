@page{margin:1em}
html,body{
	width:1100px;
	background:transparent;
	margin:0;
	padding:0
	}
body{
	color:#000 !important;
	font-family: Arial, sans-serif!important;
}


.main-header{
	height: 100px;
	position: relative!important;
	transform: none!important;
	background: transparent;
	border-bottom: 1px solid #ddd;

	.logo{
		width: 90px;
	}
}

.main-footer{
	background: #fff!important;
	color: #000!important;
	border-top: 1px solid #ddd;
	page-break-inside: avoid!important;

	div{
		color: #000!important;
	}
}

.segment--gray{
	background: #fff;
}

.no-print{
	display: none!important;
}

.only-print{
	display: block;
}

.grid.collapseprint{
	>.col{
		width: 100%!important;
		float: none;
	}
}